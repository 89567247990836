import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import SideBar from '../components/SideBar';

const IndexPage = () => (
  <Layout>
    <SideBar />
    <div id="wrapper">
      <div id="main">
        <section>
          <div className="container">
            <section>
              <h1>Page Not Found</h1>
              <Link to="/">
                <button>Back 🏡</button>
              </Link>
            </section>
          </div>
        </section>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
